// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-balia-ogrodowa-180-cm-js": () => import("./../src/pages/balia-ogrodowa-180-cm.js" /* webpackChunkName: "component---src-pages-balia-ogrodowa-180-cm-js" */),
  "component---src-pages-balia-ogrodowa-z-piecem-js": () => import("./../src/pages/balia-ogrodowa-z-piecem.js" /* webpackChunkName: "component---src-pages-balia-ogrodowa-z-piecem-js" */),
  "component---src-pages-balia-ogrodowe-165-cm-js": () => import("./../src/pages/balia-ogrodowe-165-cm.js" /* webpackChunkName: "component---src-pages-balia-ogrodowe-165-cm-js" */),
  "component---src-pages-balia-swierkowa-165-cm-x-125-cm-js": () => import("./../src/pages/balia-swierkowa-165cm-x-125cm.js" /* webpackChunkName: "component---src-pages-balia-swierkowa-165-cm-x-125-cm-js" */),
  "component---src-pages-beczka-po-saunie-js": () => import("./../src/pages/beczka-po-saunie.js" /* webpackChunkName: "component---src-pages-beczka-po-saunie-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-domek-letniskowy-5-m-na-3-m-js": () => import("./../src/pages/domek-letniskowy-5-m-na-3-m.js" /* webpackChunkName: "component---src-pages-domek-letniskowy-5-m-na-3-m-js" */),
  "component---src-pages-domek-letniskowy-5-m-na-4-m-45-mm-js": () => import("./../src/pages/domek-letniskowy-5-m-na-4-m-45mm.js" /* webpackChunkName: "component---src-pages-domek-letniskowy-5-m-na-4-m-45-mm-js" */),
  "component---src-pages-domek-letniskowy-5-m-na-4-m-js": () => import("./../src/pages/domek-letniskowy-5-m-na-4-m.js" /* webpackChunkName: "component---src-pages-domek-letniskowy-5-m-na-4-m-js" */),
  "component---src-pages-domek-letniskowy-5-m-na-5-m-45-mm-js": () => import("./../src/pages/domek-letniskowy-5-m-na-5-m-45mm.js" /* webpackChunkName: "component---src-pages-domek-letniskowy-5-m-na-5-m-45-mm-js" */),
  "component---src-pages-domek-letniskowy-6-m-na-4-m-js": () => import("./../src/pages/domek-letniskowy-6-m-na-4-m.js" /* webpackChunkName: "component---src-pages-domek-letniskowy-6-m-na-4-m-js" */),
  "component---src-pages-domek-ogrodowy-3-m-na-2-m-js": () => import("./../src/pages/domek-ogrodowy-3-m-na-2-m.js" /* webpackChunkName: "component---src-pages-domek-ogrodowy-3-m-na-2-m-js" */),
  "component---src-pages-domek-ogrodowy-3-m-na-3-m-js": () => import("./../src/pages/domek-ogrodowy-3-m-na-3-m.js" /* webpackChunkName: "component---src-pages-domek-ogrodowy-3-m-na-3-m-js" */),
  "component---src-pages-domek-ogrodowy-4-m-na-3-m-js": () => import("./../src/pages/domek-ogrodowy-4-m-na-3-m.js" /* webpackChunkName: "component---src-pages-domek-ogrodowy-4-m-na-3-m-js" */),
  "component---src-pages-domek-ogrodowy-4-m-na-4-m-45-mm-js": () => import("./../src/pages/domek-ogrodowy-4-m-na-4-m-45mm.js" /* webpackChunkName: "component---src-pages-domek-ogrodowy-4-m-na-4-m-45-mm-js" */),
  "component---src-pages-domek-ogrodowy-4-m-na-4-m-js": () => import("./../src/pages/domek-ogrodowy-4-m-na-4-m.js" /* webpackChunkName: "component---src-pages-domek-ogrodowy-4-m-na-4-m-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jacuzzi-12-dysz-led-js": () => import("./../src/pages/jacuzzi-12-dysz-led.js" /* webpackChunkName: "component---src-pages-jacuzzi-12-dysz-led-js" */),
  "component---src-pages-jacuzzi-ogrodowe-210-cm-x-193-cm-js": () => import("./../src/pages/jacuzzi-ogrodowe-210-cm-x-193-cm.js" /* webpackChunkName: "component---src-pages-jacuzzi-ogrodowe-210-cm-x-193-cm-js" */),
  "component---src-pages-jacuzzi-okragle-200-cm-js": () => import("./../src/pages/jacuzzi-okragle-200cm.js" /* webpackChunkName: "component---src-pages-jacuzzi-okragle-200-cm-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-sauna-ogrodowa-200-cm-js": () => import("./../src/pages/sauna-ogrodowa-200-cm.js" /* webpackChunkName: "component---src-pages-sauna-ogrodowa-200-cm-js" */),
  "component---src-pages-sauna-ogrodowa-350-cm-js": () => import("./../src/pages/sauna-ogrodowa-350-cm.js" /* webpackChunkName: "component---src-pages-sauna-ogrodowa-350-cm-js" */),
  "component---src-pages-sauna-ogrodowa-z-przedsionkiem-js": () => import("./../src/pages/sauna-ogrodowa-z-przedsionkiem.js" /* webpackChunkName: "component---src-pages-sauna-ogrodowa-z-przedsionkiem-js" */),
  "component---src-pages-sauna-ogrodowa-js": () => import("./../src/pages/sauna-ogrodowa.js" /* webpackChunkName: "component---src-pages-sauna-ogrodowa-js" */)
}

